<template>
  <div>
    <CCard>
      <CCardBody class="pt-1">
        <ValidationObserver ref="config_theme" v-slot="{ handleSubmit }">
          <form
            id="config_theme"
            @submit.prevent="handleSubmit()"
            v-on:keypress.enter.prevent
            autocomplete="off"
          >
            <p class="text-right">
              <small @click="showHint()">
                <CIcon
                  style="width: 2rem !important; height: 1.5rem !important"
                  class="text-primary cursor-pointer"
                  name="cil-info"
                />
              </small>
            </p>
            <CRow class="row mb-3 mt-3">
              <CRow
                class="justify-content-center m-0"
                style="width: 100%"
                v-if="fetchImageUrl"
              >
                <div
                  v-if="documentName == 'header_logo_url'"
                  style="
                    width: 12.1875em;
                    height: 62px;
                    border: 1px dashed gray;
                  "
                  class="text-center parent_div"
                >
                  <img
                    id="preview_img"
                    :src="getImageUrl"
                    @error="onErrorImg"
                    @load="onLoadImg"
                    class="p-1 d-inline-block mw-100 child_div"
                    style="max-height: 61px"
                    alt="logo"
                  />
                </div>
                <div
                  v-if="documentName == 'login_logo_url'"
                  style="width: 312px; height: 120px; border: 1px dashed gray"
                  class="text-center parent_div"
                >
                  <img
                    id="preview_img"
                    :src="getImageUrl"
                    @error="onErrorImg"
                    @load="onLoadImg"
                    class="p-1 d-inline-block mw-100 child_div"
                    style="max-height: 120px"
                    alt="logo"
                  />
                </div>
                <div
                  v-if="documentName == 'customer_logo_url'"
                  style="width: 134px; height: 61px; border: 1px dashed gray"
                  class="text-center parent_div"
                >
                  <img
                    id="preview_img"
                    :src="getImageUrl"
                    @error="onErrorImg"
                    @load="onLoadImg"
                    style="width: 130px; max-height: 50px"
                    class="child_div"
                    alt="logo"
                  />
                </div>
                <div
                  v-if="documentName == 'banner_path_url'"
                  style="border: 1px dashed gray; width: 50%"
                  class="text-center"
                >
                  <img
                    id="preview_img"
                    :src="getImageUrl"
                    @error="onErrorImg"
                    @load="onLoadImg"
                    style="width: 99%; max-height: 400px"
                    alt="logo"
                  />
                </div>
              </CRow>
              <CRow
                name="OrganisationUrl"
                class="mb-3 col-lg-12 col-md-12"
                v-if="documentName == 'header_logo_url'"
              >
                <label class="col-lg-2 col-md-2">Organisation URL</label>
                <ValidationProvider rules="url" v-slot="{ errors }">
                  <TextInput
                    name="orgUrl"
                    :value="orgUrl"
                    @input="handleUrl"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </CRow>
              <label class="mt-3 col-lg-12 col-md-12">Upload Image</label>
              <CRow class="justify-content-between" style="width: 100%">
                <div class="upload_file ml-4 mt-2 mb-3">
                  <div>
                    <input
                      id="upload-image"
                      name="upload-image"
                      type="file"
                      ref="logoref"
                      :accept="fileType"
                      @change="handleFile"
                    />
                    <label
                      for="upload-image"
                      class="btn text-white font-weight-bolder rounded-2"
                    >
                      Select File
                    </label>
                    <CButton
                      class="btn-primary ml-3"
                      v-if="isImgUpdated"
                      @click="useCrop()"
                    >
                      Use Crop
                    </CButton>
                  </div>
                  <p
                    v-if="isImgUpdated"
                    class="small text-center mt-2"
                    style="font-size: 12px; color: red; font-weight: 600"
                  >
                    Use crop for Larger Images
                  </p>
                </div>
                <div v-if="isImgUpdated || isUrlUpdated" class="ml-3">
                  <CButton class="btn-secondary mr-3" @click="cancel()">
                    Cancel
                  </CButton>
                  <CButton class="btn-primary" @click="submit()">
                    Update
                  </CButton>
                </div>
              </CRow>

              <div v-if="cropperModal.isShowPopup">
                <CropperModal
                  :isShowPopup="cropperModal.isShowPopup"
                  :fileData="cropperModal.fileData"
                  modalColor="primary"
                  :buttonLabel="cropperModal.buttonLabel"
                  @modalCallBack="cropperModalCallBack"
                  :aspectRatio="2"
                />
              </div>
            </CRow>
            <div class="documentScrolling">
              <table class="table">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ getDocumentName }}</td>
                    <td>
                      <CButton
                        class="p-0"
                        variant="ghost"
                        :disabled="!isImageExists"
                        @click="deleteDocument()"
                      >
                        <em class="fas fa-trash"></em>
                      </CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </ValidationObserver>
      </CCardBody>
    </CCard>
    <CToaster v-if="errorToaster.isError" position="bottom-right">
      <CToast :show="true" class="bg-danger text-white">
        {{ errorToaster.message }}
      </CToast>
    </CToaster>
    <modal
      modalTitle="NOTIFICATION"
      modalColor="primary"
      modalContent="Are you sure to delete this picture?"
      :isShowPopup="deleteModal.popup"
      :buttons="['Cancel', 'Confirm']"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
    <CModal
      title="HINT"
      color="primary"
      @update:show="hintModal = false"
      :show.sync="hintModal"
      size="xl"
    >
      <div>
        <img src="img/org_files_upload_hint.png" alt="hint-log" width="100%" />
      </div>
      <template #footer-wrapper>
        <footer class="modal-footer">
          <button @click="hintModal = false" class="btn btn-primary">Ok</button>
        </footer>
      </template>
    </CModal>
  </div>
</template>
<script>
import Modal from "../../components/reusable/Modal.vue";
import CropperModal from "@/components/reusable/CropperModal";
import TextInput from "@/components/reusable/Fields/TextInput";
import { extend } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
extend("url", (value) => {
  const urlRegex =
    "((http|https)://)(www.)?" +
    "[a-zA-Z0-9@:%._\\+~#?&//=]" +
    "{2,256}\\.[a-z]" +
    "{2,6}\\b([-a-zA-Z0-9@:%" +
    "._\\+~#?&//=]*)";
  if (!value.match(urlRegex)) {
    return "please Enter valid URL with http://";
  }
  return true;
});

export default {
  name: "UploadConfigThemeFiles",
  props: ["documentName", "orgData", "updateOrgThemeManually"],
  components: {
    CropperModal,
    Modal,
    TextInput,
    extend,
  },
  data() {
    return {
      payload: {},
      fileType: "image/png",
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
      },
      errorToaster: {
        isError: false,
        message: null,
      },
      deleteModal: {
        popup: false,
        item: {},
      },
      isImageExists: true,
      tempFile: {},
      hintModal: false,
      imgUrl: "",
      orgUrl: "",
      isImgUpdated: false,
      isUrlUpdated: false,
    };
  },
  computed: {
    ...mapGetters(["getOrgConfigThemeFiles"]),
    getDocumentName() {
      if (this.documentName == "header_logo_url") return "Header Logo";
      if (this.documentName == "login_logo_url") return "Login Logo";
      if (this.documentName == "customer_logo_url") return "Plain Logo";
      if (this.documentName == "banner_path_url") return "Landing Page Image";
    },
    fetchImageUrl() {
      let date = new Date();
      if (this.payload?.file) {
        this.imgUrl = URL.createObjectURL(this.payload.file);
      } else {
        // Always add some random numbers to overcome cache problem with the browser
        this.imgUrl = this.getOrgConfigThemeFiles[this.documentName]
          ? `${
              this.getOrgConfigThemeFiles[this.documentName]
            }?${date.getTime()}`
          : "";
      }
      return true;
    },
    getImageUrl: {
      get() {
        return this.imgUrl;
      },
      set(value) {
        this.imgUrl = value;
      },
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "postConfigThemeFiles",
      "deleteConfigThemeFiles",
      "updateOrganisation",
    ]),
    onLoadImg() {
      if (this.isImgUpdated) return;
      if (
        [
          `${window.location.protocol}//${window.location.host}/img/talentfind_logo2.png`,
          `${window.location.protocol}//${window.location.host}/img/new_home_bg.png`,
        ].includes(document.getElementById("preview_img")?.src || "")
      ) {
        this.isImageExists = false;
      } else {
        this.isImageExists = true;
      }
    },
    onErrorImg() {
      this.isImageExists = false;
      if (this.documentName == "banner_path_url")
        document.getElementById("preview_img").src = "/img/new_home_bg.png";
      else
        document.getElementById("preview_img").src =
          "/img/talentfind_logo2.png";
    },
    showToaster({ message }) {
      this.$refs.logoref.value = "";
      this.errorToaster.isError = true;
      this.errorToaster.message = message;
      setTimeout(() => {
        this.errorToaster.isError = false;
        this.errorToaster.message = null;
      }, 5000);
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    handleFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.payload = {
              ...this.payload,
              file,
              fileName: file.name ? file.name : "untitled.png",
            };
            this.tempFile = file;
            this.$emit("dataUpdated", true);
            this.isImgUpdated = true;
          }
        } else {
          const message = "Only png allowed";
          this.showToaster({ message });
        }
      } else {
        const message = "File too Big, please select a file less than 2mb";
        this.showToaster({ message });
      }
    },
    handleUrl(name, value) {
      this[name] = value;
      this.$emit("dataUpdated", true);
      this.isUrlUpdated = true;
    },
    useCrop() {
      this.cropperModal.fileData = this.tempFile;
      this.cropperModal.isShowPopup = true;
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
      } else {
        let { fileBlob, fileName } = data;
        this.payload = { ...this.payload, fileName };
        this.payload.file = fileBlob;
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.$emit("dataUpdated", true);
        this.isImgUpdated = true;
      }
    },
    async submit() {
      const isValid = await this.$refs.config_theme.validate();
      const errors = Object.values(this.$refs.config_theme.errors).flat();
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el?.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: errors[0] || "please fill mandatory fields!",
        });
        return;
      }
      let appendAction = [];
      if (this.isUrlUpdated) {
        let finalPayload = {
          organisation_id: this.orgData?.organisation_id,
          website: this.orgUrl,
        };
        appendAction = [
          ...appendAction,
          this.updateOrganisation(finalPayload).then(() => {
            this.updateOrgThemeManually(finalPayload);
          }),
        ];
      }
      if (this.payload.file) {
        let docName = this.documentName.replace("_url", "");
        let finalPayload = {
          acronym: this.orgData?.path_acronym,
          documentName: docName == "banner_path" ? "banner" : docName,
          ...this.payload,
        };
        appendAction = [
          ...appendAction,
          this.postConfigThemeFiles(finalPayload),
        ];
      }
      Promise.all(appendAction).then(() => {
        this.resetForm();
      });
    },
    deleteDocument(item) {
      this.deleteModal.popup = true;
      this.deleteModal.item = item;
    },
    deleteModalCallBack(action) {
      if (action == "Confirm") {
        let docName = this.documentName.replace("_url", "");
        let finalPayload = {
          acronym: this.orgData?.path_acronym,
          documentName:
            docName == "banner_path" ? "delete_banner" : `delete_${docName}`,
        };
        this.deleteConfigThemeFiles(finalPayload);
        this.deleteModal.popup = false;
      } else {
        this.deleteModal.popup = false;
      }
    },
    resetForm() {
      this.$emit("dataUpdated", false);
      this.$refs.logoref.value = "";
      this.payload = {};
      this.tempFile = {};
      let date = new Date();
      this.getImageUrl = this.getOrgConfigThemeFiles[this.documentName]
        ? `${this.getOrgConfigThemeFiles[this.documentName]}?${date.getTime()}`
        : "";
      this.isUrlUpdated = false;
      this.isImgUpdated = false;
    },
    cancel() {
      this.orgUrl = this.orgData.website;
      this.resetForm();
    },
    showHint() {
      this.hintModal = true;
    },
  },
  mounted() {
    this.orgUrl = this.orgData.website;
  },
};
</script>

<style lang="scss" scoped>
.upload_file #upload-image {
  display: none;
}
.upload_file #upload-image + label {
  display: inline-block;
  text-align: center;
  margin-bottom: 0;
  background: #dd3651;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  width: 120px;
}
.upload_file #upload-image + label:hover {
  background: #ee4763;
}
.upload_file #upload-image + label:after {
  color: #ee4763;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.parent_div {
  position: relative;
}
.child_div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
